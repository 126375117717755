/* eslint-disable consistent-return */
import { jwtDecode } from 'jwt-decode';
import Router from 'next/router';
import request from 'utils/request';
import Cookies from 'js-cookie';

export const setToken = (token) => {
  Cookies.set('userToken', token);
};

export const removeToken = () => {
  Cookies.remove('userToken');
};

export const getToken = () => {
  const token = Cookies.get('userToken');
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp && decodedToken.exp < Date.now() / 1000) {
      removeToken();
    } else {
      return token;
    }
  } else {
    return undefined;
  }

  return null;
};

export const isTokenExist = () => {
  const token = getToken();
  return !!token;
};

export const deleteRegisterStep = () => {
  Cookies.remove('registerStep');
};

export const updateRegisterStep = async (token) => {
  if (token) {
    Cookies.set('registerStep', token, { expires: 1 / 48 });
  } else {
    const oldToken = Cookies.get('registerStep');
    const { newToken } = await request('api/v1/auth/refreshRegisterToken', 'post', {
      body: JSON.stringify({
        oldToken,
      }),
    });
    Cookies.set('registerStep', newToken, { expires: 1 / 48 });
  }
};

export const checkRegisterStep = ({
  token, step, email, id, userToken,
}) => {
  const foundToken = userToken || (token || Cookies.get('registerStep'));
  if (foundToken) {
    const decodedToken = jwtDecode(foundToken);
    if (decodedToken.exp && decodedToken.exp > Date.now() / 1000) {
      if ((step === 'email-verification' && decodedToken.email === email && !decodedToken.verifiedEmail)
        || (step === 'phone-enterence' && !decodedToken.verifiedPhone && (decodedToken.id === id || decodedToken.sub === id))
        || (step === 'code-enterence' && !decodedToken.verifiedPhone && (decodedToken.id === id || decodedToken.sub === id))
        || (step === 'email-verify-success' && !decodedToken.verifiedEmail)
        || (step === 'create-company' && (decodedToken.id === id || decodedToken.sub === id) && decodedToken.verifiedPhone)
        || step === 'register-success') {
        return true;
      }
    }
  }
  return false;
};

export const handlePhoneVerifyFlow = async (token) => {
  await updateRegisterStep(token);
  const decodedToken = jwtDecode(token);
  Router.push(`/register/phone-enterence?id=${decodedToken.sub}`);
};

export const getUserData = async () => {
  const data = await request('api/v1/auth/currentUser', 'get');
  return data;
};

export const login = async (email, password) => {
  const { token } = await request('api/v1/auth/login', 'post', {
    body: JSON.stringify({
      emailOrPhone: email,
      password,
    }),
  });

  if (token) {
    const { data } = jwtDecode(token);
    setToken(token);
    return { user: data };
  }
};

export const register = async ({
  name, surname, email, country, password, confirmPassword, membershipType,
}) => {
  const { user } = await request('api/v1/auth/register', 'post', {
    body: JSON.stringify({
      name,
      surname,
      email,
      country,
      password,
      confirmPassword,
      membershipType,
    }),
  });
  return user;
};

export const verifyEmail = async (email) => {
  const data = await request(`api/v1/auth/verifyEmail?redirectTo=${process.env.domain}/register/email-verify-success`, 'post', {
    body: JSON.stringify({
      email,
    }),
  });
  return data;
};

export const verifyPhone = async ({ userId, phoneNumber }) => {
  const { message } = await request('api/v1/auth/verifyPhone', 'post', {
    body: JSON.stringify({
      userId,
      phoneNumber,
    }),
  });

  return message;
};

export const sendSmsCode = async ({ phoneNumber, code }) => {
  const { message } = await request(`api/v1/auth/verifyPhone?code=${code}&phoneNumber=${phoneNumber}`, 'get');
  return message;
};

export const reSendOtp = async ({ phoneNumber }) => {
  const { message } = await request('api/v1/auth/resendOtp', 'post', {
    body: JSON.stringify({
      phoneNumber,
    }),
  });

  return message;
};

export const createCompany = async ({
  userId, title, taxOffice, taxNo,
  address, country, region, district,
  postalCode, position, sector, businessType,
  emergencyContactName, emergencyContactSurname, emergencyContactTitle, emergencyContactPhoneCode,
  emergencyContactPhone, isBuyer, isSeller, learnedFrom, machineParkSize,
}) => {
  const data = await request(`api/v1/auth/businessInfo/${userId}`, 'post', {
    body: JSON.stringify({
      title,
      taxOffice,
      taxNo,
      address,
      country,
      region,
      district,
      postalCode,
      position,
      sector,
      businessType,
      emergencyContactName,
      emergencyContactSurname,
      emergencyContactTitle,
      emergencyContactPhone: emergencyContactPhoneCode + emergencyContactPhone,
      isBuyer,
      isSeller,
      learnedFrom,
      machineParkSize,
    }),
  });
  return data;
};

export const forgotPassword = async (email) => {
  const data = await request(`api/v1/auth/forgotPassword?redirectTo=${process.env.domain}/forgot-password/password-reset`, 'post', {
    body: JSON.stringify({
      email,
    }),
  });
  return data;
};

export const changePassword = async ({ password, token }) => {
  const data = await request(`api/v1/auth/changePassword?token=${token}`, 'post', {
    body: JSON.stringify({
      newPassword: password,
    }),
  });
  return data;
};

export const updateUser = async ({
  name,
  surname,
  country,
  email,
  identification,
  passportNumber,
  phone,
  birthdate,
  landPhone,
  gender,
  fax,
  companyTitle,
  companyEmergencyContactName,
  companyEmergencyContactSurname,
  companyEmergencyContactTitle,
  companyEmergencyContactEmail,
  companyEmergencyContactPhone,
  companyFax,
  companyTaxOffice,
  companyTaxNo,
  companyAddress,
  companySector,
}) => {
  const data = await request('api/v1/auth/currentUser/profile', 'post', {
    body: JSON.stringify({
      name,
      surname,
      country,
      email,
      identification,
      passportNumber,
      phone,
      birthdate,
      landPhone,
      gender,
      fax,
      companyTitle,
      companyEmergencyContactName,
      companyEmergencyContactSurname,
      companyEmergencyContactTitle,
      companyEmergencyContactEmail,
      companyEmergencyContactPhone,
      companyFax,
      companyTaxOffice,
      companyTaxNo,
      companyAddress,
      companySector,
    }),
  });
  return data;
};

export const getWatchlist = async (userId) => {
  const { watchList = {} } = await request(`api/v1/user/${userId}/watchlist`, 'get');
  return watchList;
};

export const addToWatchList = async (userId, machineId) => {
  const data = await request(`api/v1/user/${userId}/watchlist`, 'post', {
    body: JSON.stringify({
      machineId,
    }),
  });
  return data;
};

export const deleteFromWatchList = async (userId, machineId) => {
  const data = await request(`api/v1/user/${userId}/watchlist`, 'put', {
    body: JSON.stringify({
      machineId,
    }),
  });
  return data;
};

export const getPurchases = async (userId) => {
  const { purchases = {} } = await request(`api/v1/user/${userId}/purchases`, 'get');
  return purchases;
};

export const getOffers = async (userId) => {
  const { offers = {} } = await request(`api/v1/user/${userId}/offers`, 'get');
  return offers;
};

export const getSales = async (userId) => {
  const { sales = {} } = await request(`api/v1/user/${userId}/sales`, 'get');
  return sales;
};

export const getUserPayments = async () => {
  const { allPayments } = await request('api/v1/payment/myDepositPayment', 'get');
  return allPayments;
};

export const updateBuyingLimit = async (userId) => {
  const { success } = await request(`api/v1/user/${userId}/buyingLimitRequests  `, 'get');
  return success;
};

export const changeUserPassword = async ({ oldPassword, newPassword, userId }) => {
  const data = await request(`api/v1/user/${userId}/changePassword`, 'post', {
    body: JSON.stringify({
      oldPassword,
      newPassword,
    }),
  });
  return data;
};

export const getBankAccounts = async (accountType) => {
  const { bankAccounts } = await request(`api/v1/bankAccounts/${accountType}`, 'get');
  return bankAccounts;
};

export const getCustomerRepresentative = async (userId) => {
  const { customerRepresentative } = await request(`api/v1/customerRepresentative/${userId}`, 'get');
  return customerRepresentative;
};
